<template>
  <div class="exam5s">
    <div class="Top_box">
      <div class="select-box">
        <div>
          <span class="_Select">
            <span style="width: 50px">公司：</span>
            <el-select
              v-model="company"
              placeholder="请选择公司"
              @change="changCompany"
            >
              <el-option
                v-for="item in companyList"
                :key="item.value"
                :label="item.companyName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </span>
          <span class="_Select">
            <span style="width: 70px">状态：</span>
            <el-select
              v-model="status"
              clearable
              placeholder="请选择状态"
              @change="getTableList"
            >
              <!-- { label: '全部', value: }, -->
              <el-option
                v-for="item in [
                  { label: '已完成', value: 2 },
                  { label: '未完成', value: 4 },
                  { label: '有问题点', value: 5 },
                ]"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </span>
          <div class="_Select year">
            <span style="width: 70px">年份：</span>
            <el-date-picker
              v-model="year"
              format="yyyy"
              value-format="yyyy"
              type="year"
              placeholder="选择年"
              @change="getTableList"
            >
            </el-date-picker>
          </div>
          <div class="_Select year">
            <span style="width: 70px">级别：</span>
            <el-select
              @change="getTableList(false)"
              v-model="level"
              clearable
              placeholder="请选择"
            >
              <el-option
                v-for="item in levelList"
                :key="item.value"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div>
          <el-button
            style="background: #e9f7ff; color: #7caffc"
            @click="openFallowDialog"
            ><i class="el-icon-circle-plus-outline el-icon--right"></i
            >问题整改</el-button
          >
          <el-button type="primary" class="edit" @click="openDrawDialog"
            ><i class="el-icon-circle-plus-outline el-icon--right"></i
            >抽取题目</el-button
          >
        </div>
      </div>
      <div class="box_">
        <span>区域：</span>
        <div
          class="_item"
          v-for="(item, index) in areaList"
          :key="index"
          @click="chooseArea(item, index)"
          :v-model="areaId"
          :value="item.areaName"
          :class="{
            action: areaIndex == index,
          }"
        >
          <span> {{ item.areaName }}</span>
        </div>
      </div>
      <div class="_TableBox">
        <el-table
          :data="tableData.slice(start, end)"
          tooltip-effect="dark"
          :height="elementHeight"
          style="width: 100%"
          highlight-current-row
          :stripe="true"
          @row-dblclick="getPage"
        >
          <!-- <el-table-column type="selection" width="50"> </el-table-column> -->
          <el-table-column type="index" label="序号" width="50">
          </el-table-column>
          <el-table-column label="所在区域" prop="auditAreaName" width="120">
          </el-table-column>

          <el-table-column
            label="抽取问题数"
            prop="titleCount"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="failCount"
            label="问题点数"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column prop="status" label="检查状态" show-overflow-tooltip>
            <template slot-scope="scope">
              <span class="status" v-show="scope.row.status === 0">
                未抽题
              </span>
              <span class="status" v-show="scope.row.status === 1">
                已抽题</span
              >
              <span class="status" v-show="scope.row.status === 2">
                已完成</span
              >
              <span class="status" v-show="scope.row.status === 3">
                整改完成</span
              >
              <span class="status" v-show="scope.row.status === 4">
                未完成</span
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="completeTime"
            label="完成时间"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <span>{{ scope.row.completeTime | time }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="saveTime"
            label="创建时间"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <span>{{ scope.row.saveTime | time }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="soces" label="得分" show-overflow-tooltip>
            <template slot-scope="scope">
              <span class="soces">{{ scope.row.soces }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="saveUserName"
            label="创建人"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column prop="remark" label="备注" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="" label="操作">
            <template slot-scope="scope">
              <i class="el-icon-delete" @click="delItem(scope.row.id)"></i>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage"
          :page-sizes="[10, 20, 50]"
          :page-size="curSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="tableData.length"
          class="_Pagination"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog title="新抽问题" :visible.sync="showDraw" width="20%" top="20vh">
      <div style="padding-top: 20px">
        <span style="width: 100px; padding-right: 10px">所属公司</span>
        <el-select
          v-model="company"
          placeholder="请选择公司"
          @change="changCompany"
        >
          <el-option
            v-for="item in companyList"
            :key="item.value"
            :label="item.companyName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div style="padding-top: 10px">
        <span style="width: 100px; padding-right: 10px">所属区域</span>
        <el-select
          v-model="areaId"
          @change="getDrawTitle"
          placeholder="请选择区域"
        >
          <el-option
            v-for="item in areaList"
            :key="item.value"
            :label="item.areaName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div style="display: flex; margin-top: 10px">
        <span
          style="margin-left: 2em; padding-right: 10px; display: inline-block"
          >级别</span
        >
        <el-select
          @change="getDrawTitle"
          v-model="level"
          clearable
          placeholder="请选择"
        >
          <el-option
            v-for="item in levelList"
            :key="item.value"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
      </div>
      <div style="text-align: end; color: #4371ee; margin-top: 10px">
        <span style="margin-right: 4em"
          >总题数：{{ drawCount.titleCount ? drawCount.titleCount : 0 }}</span
        >
        <span
          >抽取题数：{{ drawCount.titleCount ? drawCount.titleCount : 0 }}</span
        >
      </div>
      <div slot="footer">
        <el-button type="primary" @click="postDraw">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="问题整改"
      :visible.sync="showFollowData"
      width="90%"
      top="5vh"
    >
      <div class="_Tab">
        <div>
          <div
            style="text-align: center; width: 80px"
            v-for="(item, index) in tabList"
            :key="index"
            class="tablist"
          >
            <div
              @click="clickTab(index)"
              :class="{ activeC: index == tabIndex }"
            >
              {{ item }}
            </div>
            <div
              :class="{ activeBorder: index == tabIndex }"
              style="width: 35px"
            ></div>
          </div>
        </div>
        <el-button style="background: #ffa400; color: white"
          ><i class="el-icon-circle-plus-outline el-icon--right"></i
          >全部发出报告</el-button
        >
      </div>
      <div class="_TableBox">
        <el-table
          :data="followData"
          style="width: 100%"
          :stripe="true"
          :height="elementHeight"
        >
          <el-table-column type="selection" width="50"> </el-table-column>
          <el-table-column type="index" label="序号" width="50">
          </el-table-column>
          <el-table-column prop="checkResult" label="审核发现">
          </el-table-column>
          <el-table-column prop="trackTimes" label="跟进次数">
          </el-table-column>
          <el-table-column prop="checkRecord" label="不合格描述">
          </el-table-column>
          <template v-if="tabIndex != 0">
            <el-table-column
              prop="correctiveDeadlines"
              label="纠正日期"
              width="180"
            >
              <template slot-scope="scope">
                <span>{{ scope.row.correctiveDeadlines | time }}</span>
              </template>
            </el-table-column>
            <el-table-column label="现场证据" width="180">
              <template slot-scope="scope">
                <el-button size="small" @click="clickImageBtn(scope.row, 0)"
                  >共 {{ scope.row.picCount }} 张</el-button
                >
              </template>
            </el-table-column>
            <el-table-column prop="sendTrackName" label="发送人" width="100">
            </el-table-column>

            <el-table-column prop="sendTrackDate" label="发送时间" width="150">
              <template slot-scope="scope">
                <span>{{ scope.row.sendTrackDate | time }}</span>
              </template>
            </el-table-column>
          </template>
          <!-- <el-table-column prop="fileName" label="文件名" width="250">
        </el-table-column> -->
          <template v-if="tabIndex >= 2">
            <el-table-column prop="" label="整改描述" width="150">
            </el-table-column>
            <el-table-column prop="sendProvenName" label="提交人" width="180">
            </el-table-column>
            <el-table-column prop="sendProvenDate" label="提交日期" width="180">
              <template slot-scope="scope">
                <span>{{ scope.row.sendProvenDate | time }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="sureProvenName" label="验证人" width="180">
            </el-table-column>
            <el-table-column prop="sureProvenDate" label="验证日期" width="180">
              <template slot-scope="scope">
                <span>{{ scope.row.sureProvenDate | time }}</span>
              </template>
            </el-table-column>
            <el-table-column label="是否通过" width="180">
              <!-- 0未通过，1通过 -->
              <span slot-scope="scope">{{
                scope.row.isPass == 1 ? "是" : "否"
              }}</span>
            </el-table-column>
            <el-table-column label="整改证据" width="180">
              <template slot-scope="scope">
                <el-button size="small" @click="clickImageBtn(scope.row, 1)"
                  >共 {{ scope.row.pic2Count }} 张</el-button
                >
              </template>
            </el-table-column>
          </template>
          <el-table-column
            prop="problemWorkSitName"
            label="操作"
            v-if="tabIndex != 3"
          >
            <template slot-scope="scope">
              <el-button
                v-if="tabIndex == 0"
                type="primary"
                style="border-radius: 50px !important"
                @click="toReport(scope.row)"
                >发出报告</el-button
              >
              <!-- <el-button
                v-if="tabIndex == 1"
                type="primary"
                style="border-radius: 50px !important"
                @click="submitMeasure(scope.row)"
                >提交措施</el-button
              > -->
              <el-button
                v-if="tabIndex == 1"
                type="primary"
                style="border-radius: 50px !important"
                @click="showExamDialog(scope.row)"
                >提交验证</el-button
              >
              <el-button
                v-if="tabIndex == 2"
                type="primary"
                style="border-radius: 50px !important"
                @click="confirmExamine(scope.row)"
                >确定验证</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>
    <el-dialog
      title="发出报告"
      :visible.sync="dialogVisible"
      width="350px"
      top="5vh"
    >
      <el-form>
        <el-form-item label="不合格描述：">
          <el-input
            type="textarea"
            v-model="listItem.checkRecord"
            :rows="5"
          ></el-input>
        </el-form-item>
        <div style="display: flex; align-items: center">
          <span style="width: 80px">纠正期限：</span>
          <el-date-picker
            v-model="listItem.Dealtime"
            type="date"
            placeholder="选择日期"
            value-format="yyyy-MM-dd"
            style="width: 88%"
          >
          </el-date-picker>
        </div>
      </el-form>
      <div class="dialog-input">
        <span style="width: 80px">现场证据：</span>
        <div class="p_pic">
          <div class="imgall" style="justify-content: start">
            <span class="ImgDel" v-for="(item, index) in srcList" :key="item">
              <el-image
                style="width: 100px; height: 100px"
                :src="item"
                :preview-src-list="srcList"
                @mouseover="overimg(index)"
              >
              </el-image>

              <i
                class="el-icon-delete-solid"
                v-show="index === Curindex"
                @click="deleteImg(item)"
              ></i>
            </span>
            <el-upload
              action="https://jsonplaceholder.typicode.com/posts/"
              list-type="picture-card"
              :on-preview="handlePictureCardPreview"
              :on-remove="handleRemove"
              :http-request="uploadFile"
              class="avatar-uploader"
              ref="upload"
              :headers="token"
            >
            </el-upload>
          </div>
        </div>
      </div>
      <div slot="footer">
        <el-button type="primary" @click="reportReq">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="提交验证"
      :visible.sync="dialogVisible2"
      width="350px"
      top="5vh"
    >
      <el-form>
        <el-form-item label="整改描述">
          <el-input type="textarea" v-model="Content" :rows="5"></el-input>
        </el-form-item>
      </el-form>
      <div class="dialog-input">
        <span style="width: 80px">整改证据：</span>
        <div class="p_pic">
          <div class="imgall" style="justify-content: start">
            <span class="ImgDel" v-for="(item, index) in srcList" :key="item">
              <el-image
                style="width: 100px; height: 100px"
                :src="item"
                :preview-src-list="srcList"
                @mouseover="overimg(index)"
              >
              </el-image>

              <i
                class="el-icon-delete-solid"
                v-show="index === Curindex"
                @click="deleteImg(item)"
              ></i>
            </span>
            <el-upload
              action="https://jsonplaceholder.typicode.com/posts/"
              list-type="picture-card"
              :on-preview="handlePictureCardPreview"
              :on-remove="handleRemove"
              :http-request="uploadFile"
              class="avatar-uploader"
              ref="upload"
              :headers="token"
            >
            </el-upload>
          </div>
        </div>
      </div>
      <div slot="footer">
        <el-button type="primary" @click="reqExamine">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="确定验证"
      :visible.sync="dialogVisible3"
      width="30%"
      style="margin-top: 50px"
    >
      <div class="confirm-verification" style="padding-left: 30px">
        <div class="dialog-input">
          <span>是否通过：</span>
          <el-select v-model="isPass" placeholder="请选择是或否">
            <el-option
              v-for="item in ['是', '否']"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div slot="footer">
        <el-button type="primary" @click="passReq">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="证据照片"
      :visible.sync="dialogVisible4"
      width="25%"
      style="margin-top: 50px"
    >
      <div v-if="imgList.length != 0">
        <span class="ImgDel" v-for="item in srcList" :key="item">
          <el-image
            style="width: 100px; height: 100px"
            :src="item"
            :preview-src-list="srcList"
          >
          </el-image>
        </span>
      </div>
      <p v-if="imgList.length == 0" class="imgP">空空如也~</p>
    </el-dialog>
  </div>
</template>

<script>
import { get, post, put, deleteMethod, baseURL } from "../../api/http";
import { compressImage } from "../../modules/CompressImage";
import imgTypeChange from "../../modules/imgChange";
export default {
  data() {
    return {
      elementHeight: 0,
      companyList: [],
      company: null,
      status: "",
      year: "",
      areaList: [],
      areaIndex: null,
      areaId: '',
      areaName: "",
      tableData: [],
      level: "",
      levelList: [],
      curSize: 10,
      start: 0,
      end: 10,
      currentPage: 1,
      showDraw: false,
      drawCount: {},
      // 不符合跟踪
      userNo: this.$store.state.userInfo.userNo,
      userName: this.$store.state.userInfo.userName,
      tabIndex: 0,
      tabList: ["待发出报告", "待提交验证", "待确定验证", "已通过验证"],
      followData: [],
      followTableData1: [],
      followTableData2: [],
      followTableData3: [],
      followTableData4: [],
      showFollowData: false,
      startTime: "",
      endTime: "",
      dialogVisible: false, // 发出报告
      listItem: {},
      dealtime: "",
      showImgIndex: 0,
      token: {},
      imgList: [], // 图片
      srcList: [],
      ImgFile: [],
      fileList: [],
      baseUrl: "",
      Curindex: null,
      Content: "", //提交验证
      dialogVisible2: false,
      dialogVisible3: false, //确定验证
      isPass: "",
      dialogVisible4: false,
    };
  },
  filters: {
    time: function (e) {
      if (e === null) {
        return "";
      }
      return e.split("T")[0];
    },
  },
  mounted() {
    this.getCompany();
    this.getElementHeight();
    this.getLevelList();
    this.token = {
      Authorization:
        "Bearer " + JSON.parse(window.sessionStorage.getItem("token")),
    };
    this.baseUrl = baseURL[process.env.NODE_ENV + ""];
  },
  methods: {
    getElementHeight() {
      var clientHeight = window.innerHeight;
      this.$nextTick(() => {
        this.elementHeight = clientHeight - (60 + 55 + 60 + 70); //70是div底部距离底部的高度
      });
    },
    getLevelList() {
      get("/api/Tb5SQuestionPool/GetLevelList").then((resp) => {
        if (resp.code == 200) {
          this.levelList = resp.data;
        }
      });
    },
    getCompany() {
      this.companyList = this.$store.state.companyUserList;
      this.company = this.companyList[0].id;
      this.changCompany();
      this.getTableList();
    },
    changCompany() {
      this.areaId = '';
      this.areaName = "";
      this.areaIndex = null;
      this.areaList = [];
      get("/api/Area/GetByAreaCompId?CompId=" + this.company).then((res) => {
        res.data.map((i, index) => {
          i.index = index;
          this.areaList.push(i);
        });
      });
    },
    chooseArea(item, index) {
      if (this.areaIndex == index) {
        this.areaIndex = null;
        this.areaName = "";
        return;
      }
      this.areaName = item.areaName;
      this.areaId = item.id;
      this.areaIndex = index;
      this.getTableList();
    },
    getTableList(getPage) {
      get(
        "/api/Audit5S/GetByAreaAudit?AreaNo=" +
          this.areaId +
          "&AreaName=" +
          this.areaName +
          "&level=" +
          this.level +
          "&Status=" +
          this.status +
          "&year=" +
          this.year
      ).then((res) => {
        if (res.code == 200) {
          this.tableData = res.data;
          if (getPage) {
            this.$router.push(
              "/exam5s-list?planItem=" +
                JSON.stringify(this.tableData[0]) +
                "&PlanId=" +
                this.tableData[0].id +
                "&status=" +
                this.tableData[0].status
            );
          }
        }
      });
    },
    handleSizeChange(e) {
      this.curSize = e;
      this.start = (this.currentPage - 1) * this.curSize;
      this.end = this.start + this.curSize;
    },
    handleCurrentChange(e) {
      this.start = (e - 1) * this.curSize;
      this.end = this.start + this.curSize;
    },
    openDrawDialog() {
      this.drawCount = {};
      this.showDraw = true;
    },
    getDrawTitle() {
      if (this.areaId == null) {
        this.$message({
          type: "warning",
          message: "请选择区域",
        });
        return;
      }
      post(
        "/api/Audit5S/GetTitleList?AreaId=" +
          this.areaId +
          "&level=" +
          (this.level ? this.level : 0) +
          "&compId=" +
          this.company
      ).then((resp) => {
        if (resp.code == 200) {
          this.drawCount = resp.data;
        }
      });
    },
    postDraw() {
      var area = this.areaList.filter((i) => {
        return i.id == this.areaId;
      });
      this.areaName = area[0].areaName;
      post("/api/Audit5S/InsertAreaAudit?quesNo=" + this.drawCount.titleCount, {
        id: 0,
        auditAreaNo: this.areaId + "",
        auditAreaName: area[0].areaName,
        upCompanyId: this.company,
        level: this.level,
        auditType: 0,
        remark: "string",
        chargeUserId: this.$store.state.userInfo.id + "",
        planId: "string",
        status: 0,
        saveTime: new Date().toISOString(),
        completeTime: "2025-01-16T11:11:14.348Z",
        saveUserId: this.$store.state.userInfo.id + "",
        saveUserNo: this.$store.state.userInfo.userNo,
        saveUserName: this.$store.state.userInfo.userName,
        tenantId: JSON.parse(sessionStorage.getItem("userInfo")).tenantId,
      })
        .then((resp) => {
          if (resp.code == 200) {
            this.$message({
              type: "success",
              message: "抽题成功",
            });
            this.showDraw = false;
            this.getTableList(true);
            this.areaList.map((i, index) => {
              if (i.id == this.areaId) {
                this.areaIndex = index;
              }
            });
          } else {
            this.$message.error(resp.message);
          }
        })
        .catch(() => {
          this.$message.error("系统异常,请稍后再试");
        });
    },
    getPage(row) {
      this.$router.push(
        "/exam5s-list?planItem=" +
          JSON.stringify(row) +
          "&PlanId=" +
          row.id +
          "&status=" +
          row.status
      );
    },
    delItem(id) {
      this.$confirm("此操作将永久删除该检查, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteMethod("/api/Audit5S?Id=" + id).then((res) => {
            if (res.code == 200) {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.getTableList();
            } else {
              this.$message.error(res.message);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 不符合跟踪
    openFallowDialog() {
      this.showFollowData = true;
      this.getFollowData(0);
    },
    getFollowData(index) {
      get(
        "/api/Inap5STrack" +
          "?fromDate=" +
          this.startTime +
          "&toDate=" +
          this.endTime
      ).then((resp) => {
        if (resp.code == 200) {
          this.followTableData1 = [];
          this.followTableData2 = [];
          this.followTableData3 = [];
          this.followTableData4 = [];
          resp.data.map((i) => {
            // trackState 其值代表tabList不符合跟踪项五个，5s不符合跟踪少了第二个“待提交措施”
            if (i.trackState == 0) {
              this.followTableData1.push(i);
            } else if (i.trackState == 2) {
              this.followTableData2.push(i);
            } else if (i.trackState == 3) {
              this.followTableData3.push(i);
            } else if (i.trackState == 4) {
              this.followTableData4.push(i);
            }
          });
          this.clickTab(index);
        }
      });
    },
    clickTab(index) {
      this.tabIndex = index;
      this.followData = [];
      if (index == 0) {
        this.followData = this.followTableData1;
      } else if (index == 1) {
        this.followData = this.followTableData2;
      } else if (index == 2) {
        this.followData = this.followTableData3;
      } else {
        this.followData = this.followTableData4;
      }
    },
    // 发出报告
    toReport(row) {
      this.dialogVisible = true;
      this.listItem = row;
      this.showImgIndex = 0;
      this.reqGetPic();
    },
    async allToReport() {
      //全部发出
      if (this.followData.length == 0) return;
      var data = [];
      this.tableData.map((i) => {
        data.push({
          id: i.id,
          CheckRecord: i.checkRecord,
          UserUno: this.userNo,
          UserName: this.userName,
          Type: i.type,
        });
      });
      await put("/api/Inap5STrack/PutOneAll", { data: JSON.stringify(data) })
        .then((resp) => {
          if (resp.code == 200) {
            this.$message({
              message: "全部发出成功",
              type: "success",
            });
            this.getFollowData(this.tabIndex);
          } else {
            this.$message.error(resp.message);
          }
        })
        .catch(() => {
          this.$message.error("全部发出失败");
        });
    },
    reportReq() {
      //单个发出
      put(
        "/api/Inap5STrack/PutOne" +
          "?id=" +
          this.listItem.id +
          "&checkRecord=" +
          this.listItem.checkRecord +
          "&UserUno=" +
          this.userNo +
          "&UserName=" +
          this.userName +
          "&Type=" +
          this.listItem.type +
          "&Dealtime=" +
          this.listItem.Dealtime
      )
        .then((resp) => {
          if (resp.code == 200) {
            this.$message({
              message: "发送成功",
              type: "success",
            });
            this.dialogVisible = false;
            this.getFollowData(this.tabIndex);
          } else {
            this.$message.error(resp.message);
          }
        })
        .catch(() => {
          this.$message.error("发送失败");
        });
    },
    // 上传图片
    reqGetPic() {
      this.srcList = [];
      this.imgList = [];
      var url1 = "/api/Inad5STrackpic";
      var url2 = "/api/Inad5STrackpic2";
      var url = this.showImgIndex == 1 ? url2 : url1;
      get(url + "?id=" + this.listItem.id + "&Type=" + this.listItem.type).then(
        (resp) => {
          if (resp.code == 200) {
            this.imgList = resp.value;
            resp.value.forEach((element) => {
              this.srcList.push(element.picNameAndAddr);
            });
          }
        }
      );
    },
    overimg(index) {
      this.Curindex = index;
    },
    handleRemove(file) {
      var nIndex = this.ImgFile.findIndex((e) => e.oldName == file.name);
      this.ImgFile.splice(nIndex);
    },
    handlePictureCardPreview() {},
    async uploadFile(e) {
      if (e.file) {
        let file = await imgTypeChange(e.file);
        let newfile = await compressImage(file);
        if (newfile.size > 10000) {
          newfile = await compressImage(newfile);
        }
        this.uploadFileReq(newfile);
      }
    },
    uploadFileReq(file) {
      var formData = new FormData();
      formData.append("files", file);
      post("/api/UpLoadFiles/UploadTrackImg", formData)
        .then((resp) => {
          this.$refs.upload.clearFiles();
          if (resp.code == 200) {
            var data = {
              Id: 0,
              PicName: this.baseUrl + "/uploadTracks/" + resp.value,
              PicDescription: "",
              RelationId: this.listItem.id,
              SaveUno: this.userNo,
              SaveName: this.userName,
            };
            this.submitAddPic(data);
          } else {
            this.$message.error(resp.message);
          }
        })
        .catch(() => {
          this.$refs.upload.clearFiles();
          this.$message.error("上传失败");
        });
    },
    submitAddPic(data) {
      var url1 = "/api/Inad5STrackpic";
      var url2 = "/api/Inad5STrackpic2";
      var url = this.showImgIndex == 1 ? url2 : url1;
      post(url + "?Type=" + this.listItem.type, {
        data: JSON.stringify([data]),
      })
        .then((resp) => {
          if (resp.code == 200) {
            this.$message({
              message: "上传成功",
              type: "success",
            });
            this.$refs.upload.clearFiles();
            this.reqGetPic();
          } else {
            this.$message.error(resp.message);
          }
        })
        .catch(() => {
          this.$message.error("上传失败");
        });
    },
    // 删除图片
    deleteImg(item) {
      this.imgList.forEach((e) => {
        if (e.picNameAndAddr === item) {
          this.$confirm("此操作将永久删除该图片", "是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              var url1 = "/api/Inad5STrackpic";
              var url2 = "/api/Inad5STrackpic2";
              var url = this.showImgIndex == 1 ? url2 : url1;
              deleteMethod(url, {
                Id: e.id,
                Type: this.listItem.type,
              }).then(() => {
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
                this.reqGetPic();
              });
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消删除",
              });
            });
        }
      });
    },
    // 查看图片
    clickImageBtn(row, index) {
      this.dialogVisible4 = true;
      this.listItem = row;
      this.showImgIndex = index;
      this.reqGetPic();
    },
    showExamDialog(row) {
      this.listItem = row;
      this.dialogVisible2 = true;
      this.showImgIndex = 1;
    },
    // 提交验证
    reqExamine() {
      put(
        "/api/Inap5STrack/PutThere?id=" +
          this.listItem.id +
          "&UserUno=" +
          this.userNo +
          "&UserName=" +
          this.userName +
          "&Content=" +
          this.Content +
          "&Type=" +
          this.listItem.type
      )
        .then((resp) => {
          if (resp.code == 200) {
            this.$message({
              message: "验证成功",
              type: "success",
            });
            this.dialogVisible2 = false;
            this.getFollowData(this.tabIndex);
          } else {
            this.$message.error(resp.message);
          }
        })
        .catch(() => {
          this.$message.error("验证失败");
        });
    },
    // 确定验证
    bindIsPassInp(e) {
      this.isPass = e;
    },
    confirmExamine(row) {
      this.listItem = row;
      this.dialogVisible3 = true;
      this.isPass = "";
      this.reqGetPic();
    },
    passReq() {
      if (this.isPass == "") {
        this.$message({
          message: "请选择是否通过",
          type: "warning",
        });
        return;
      }
      put(
        "/api/Inap5STrack/PutFour?id=" +
          this.listItem.id +
          "&Content=" +
          "&isOk=" +
          (this.isPass = this.isPass == "是" ? true : false) +
          "&UserUno=" +
          this.userNo +
          "&UserName=" +
          this.userName +
          "&Type=" +
          this.listItem.type
      )
        .then((resp) => {
          if (resp.code == 200) {
            this.$message({
              message: "验证成功",
              type: "success",
            });
            this.getFollowData(this.tabIndex);
            this.dialogVisible3 = false;
          } else {
            this.$message.error(resp.message);
          }
        })
        .catch(() => {
          this.$message.error("验证失败");
        });
    },
  },
};
</script>

<style lang="less">
.exam5s {
  .el-table__body tr.current-row > td {
    background: #d4e5f9 !important;
  }

  .Top_box {
    background: none;
    padding-bottom: 0px;
  }
  .select {
    background: #e7e9ec !important;
  }

  ::v-deep .select .el-input__inner {
    //   border-color: rgba(255, 255, 255, 0.5);
    color: #000000;
    border: 0px;
    height: 49px;
    width: 240px;
    font-size: 20px;
    text-size-adjust: 20px;
    .el-input__inner::placeholder {
      color: rgb(0, 0, 0);
      text-align: center;
    }
  }
  option {
    background: #ffffff;
    line-height: 20px;
    border: 0px;
  }

  .status {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .box_ {
    display: flex;
    height: 60px;
    width: 100%;
    margin-left: 5px;
    overflow-x: auto;
    border-top: 1px solid rgba(192, 192, 192, 0.411);
    margin-top: 10px;
    align-items: center;
  }

  ::-webkit-scrollbar {
    margin-top: 3px;
    height: 6px;
    width: 0 !important;
  } //设置滚动条显示隐藏
  /* 设置滚动条的样式 */
  ::-webkit-scrollbar {
    width: 5px;
    border-radius: 8px;
  }
  /* 滚动槽 */
  ::-webkit-scrollbar-track {
    /* -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5); */
    background-color: rgb(218, 216, 216);
  }
  /* 滚动条滑块 */
  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background: rgb(92, 90, 90);
    /* -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5); */
  }

  .action {
    background: #4985ff !important;
    color: #ffffff !important;
  }
  ._item {
    width: 105px;
    height: 40px;
    flex-shrink: 0;
    color: #000000;
    background: #ffffff;
    border: 1px solid #e7e9ec;
    box-shadow: 0px 2px 8px 0px rgba(160, 164, 170, 0.36);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    cursor: pointer;
  }
  .select-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    > div:nth-of-type(1) {
      display: flex;
      align-items: center;
    }
  }
  .el-icon--right {
    margin-right: 5px;
    margin-left: 0;
  }
  /deep/ .operat-btn {
    border-radius: 50px !important;
    font-size: 10px !important;
  }
  .soces {
    width: 40px;
    display: inline-block;
    border: 1px solid #8dc4a9 !important;
    background: #eaf5ef;
    border-radius: 2px;
  }

  .el-dialog {
    .el-dialog__body {
      padding: 10px 0 !important;
    }
  }
  ._Tab {
    color: #a09f9f;
    margin-bottom: 15px;
    padding: 0 !important;
    display: flex;
    justify-content: space-between;
    > div {
      display: flex;
    }
    .tablist {
      justify-content: center;
      align-items: center;
      display: flex;
      flex-direction: column;
      height: 40px;
      line-height: 40px;
      color: black;
    }
  }
  .activeC {
    color: #2f98ff;
  }
  .activeBorder {
    border-bottom: 3px solid #2f98ff;
  }
  .dialog-input {
    display: flex;
    align-content: center;
    align-items: center;
    margin-top: 10px;
  }
  .el-upload--picture-card {
    width: 60px !important;
    height: 60px !important;
  }
  .ImgList {
    float: left;
    margin-left: 3px;
  }
  .ImgList div {
    float: left;
    width: 65px;
    position: relative;
  }
  .ImgList img {
    width: 100%;
    height: 65px;
    float: left;
  }
  .imgall {
    display: flex;
    width: 100%;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
  }
  /deep/ .el-upload-list--picture-card .el-upload-list__item {
    width: 65px;
    height: 65px;
    line-height: 65px;
  }
  .span_delete {
    position: absolute;
    display: block;
    background: red;
    color: #ffffff;
    bottom: 0;
    width: 100% !important;
    text-align: center;
  }
  .p_pic {
    margin-top: 5px;
    width: 270px;
  }
  .ImgList2 {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
  }
  .ImgList2 img {
    width: 80px;
    height: 80px;
  }
  .imgP {
    text-align: center;
    margin-bottom: 20px;
  }
  .ImgDel {
    position: relative;
  }

  .ImgDel i {
    transform: scale(1.5);
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
  }

  .el-image,
  .el-image__error,
  .el-image__inner,
  .el-image__placeholder {
    width: 60px !important;
    height: 60px !important;
  }
}

@import "../../assets/style/Examine_CSS.css";
</style>